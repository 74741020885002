import React, { useState, useCallback } from 'react';
import { FormikHelpers } from 'formik';
import classNames from 'classnames';
import { Text, Title, CustomVisibilitySensor } from 'components';
import { StyledSubtitle } from 'components/Solucoes/styles';
import {
  Wrapper,
  BackgroundImage,
  Grid,
  Content,
  StyledSingleInputForm
} from './styles';

export default function Integration() {
  return (
    <CustomVisibilitySensor offset={{ bottom: -300, top: -300 }}>
      {({ isVisible }) => (
        <Wrapper>
          <BackgroundImage
            alt=""
            className={classNames({ isVisible })}
            src={require('assets/integracao-receitas-bg.jpg')}
          />

          <Grid className="grid">
            <Content>
              <StyledSubtitle>Integração a quatro mãos</StyledSubtitle>
              <Title>Uma integração, milhares de farmácias</Title>
              <Text>
                Com apenas uma integração, você se conecta a milhares de
                farmácias e e-commerces por todo o Brasil.
              </Text>
              <StyledSingleInputForm buttonText="Saber mais" type="receitas" />
            </Content>
          </Grid>
        </Wrapper>
      )}
    </CustomVisibilitySensor>
  );
}
