import React from 'react';
import {
  FlexibilidadeImage,
  FlexibilidadeTooltip,
  AssinaturaImage,
  AssinaturaTooltip,
  BaseImage,
  BaseTooltip
} from './styles';
import { BlockWrapper, TextWrapper } from 'components/Solucoes/Tabs/styles';
import { Subtitle, Text, Title } from 'components';
import { Tabs } from 'components/Solucoes';

const data = [
  {
    title: 'Flexibilidade',
    image: (
      <FlexibilidadeImage
        src={require('assets/flexibilidade-tab.png')}
        alt=""
      />
    ),
    tooltip: (
      <FlexibilidadeTooltip
        src={require('assets/flexibilidade-tooltip.svg')}
        alt="Certificados Digitais - Nós nos integramos com todas as principais certificadoras de mercado para que seus médicos não precisem se preocupar se seu certificado funciona ou não."
      />
    )
  },
  {
    title: 'Assinatura Digital',
    image: (
      <AssinaturaImage
        src={require('assets/assinatura-digital-receitas-tab.png')}
        alt=""
      />
    ),
    tooltip: (
      <AssinaturaTooltip
        src={require('assets/assinatura-digital-receitas-tooltip.svg')}
        alt="Autenticação - Para que seja realizada uma dispensa eletrônica, é necessário o cadastro como um farmacêutico ativo no CFF com usuário e senha."
      />
    )
  },
  {
    title: 'Base de Medicamentos',
    image: <BaseImage src={require('assets/base-tab.png')} alt="" />,
    tooltip: (
      <BaseTooltip
        src={require('assets/base-tooltip.svg')}
        alt="Histórico - Com o CPF e a autorização do paciente, você consegue ver todas as prescrições eletrônicas que ele recebeu nos últimos tempos."
      />
    )
  }
];

export default function ReceitasTabs() {
  return (
    <BlockWrapper>
      <TextWrapper>
        <Subtitle>All-in-one</Subtitle>
        <Title>
          Flexibilidade. <br /> Inteligência. <br /> Robustez.
        </Title>
        <Text>
          Com o Nexodata Receitas você pode escolher entre manter a sua
          interface ou usar a nossa. Uma vez integrado, seus médicos têm acesso
          à uma base de medicamentos robusta e podem assinar suas prescrições
          com qualquer certificado digital.
        </Text>
      </TextWrapper>
      <Tabs data={data} />
    </BlockWrapper>
  );
}
