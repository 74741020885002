import React from 'react';
import { SolucoesTopBanner } from 'components/Solucoes';
import { SOLUCOES_BANNER_HEIGHT } from 'components/Solucoes/SolucoesTopBanner/styles';
import { SmallText, ScrollableWrapper, Logos } from './styles';
import { px2rem } from 'src/util';

export default function ReceitasTopBanner() {
  return (
    <SolucoesTopBanner
      image={require('assets/receitas-topo.svg')}
      imageStyle={`
        @media screen and (min-width: 998px) {
          right: ${(124 / 1920) * 100}%;
          top: ${(66 / SOLUCOES_BANNER_HEIGHT) * 100}%;
          width: ${(761 / 1920) * 100}%;
        }
      
        @media screen and (max-width: 997px) {
          bottom: 309px;
          right: -109px;
          width: 404px;
        }
      `}
      customStyle={`
        h2 {
          margin-bottom: ${px2rem(32)};
        }
        
        @media screen and (min-width: 998px) {
          > div {
            padding-top: ${(276 / 1920) * 100}%;
          }

          h2 {
            max-width: ${px2rem(560)};
          }
        }

        @media screen and (max-width: 997px) {
          h2 {
            margin-bottom: 333px;
            max-width: 240px;

            span {
              display: inline-block;
            }
          }
        }
      `}
      subtitle="Nexodata Receitas"
      title={
        <>
          Solução <span>all-in-one</span> de prescrição eletrônica com
          assinatura digital
        </>
      }
    >
      <SmallText>
        Aproveite destes serviços assim como as centenas de parceiros Nexodata
      </SmallText>
      <ScrollableWrapper>
        <Logos src={require('assets/logos-receitas.jpg')} alt="" />
      </ScrollableWrapper>
    </SolucoesTopBanner>
  );
}
