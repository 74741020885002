import styled from 'styled-components';
import { px2rem } from 'src/util';
import LoadImage from 'components/LoadImage';
import Title from 'components/Title';
import Text from 'components/Text';
import DiagonalCornersBar from 'assets/diagonal-corners-bar.svg';

export const Wrapper = styled.section`
  background-color: #dee2e6;

  @media screen and (min-width: 998px) {
    display: flex;
    height: ${px2rem(540)};
    justify-content: center;
  }

  @media screen and (max-width: 997px) {
  }
`;

export const Grid = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 14;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 16;
  }

  @media screen and (min-width: 998px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
    padding: 72px 20px;
  }
`;

export const StyledImage = styled(LoadImage)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(472)};
    width: ${px2rem(424)};
  }

  @media screen and (max-width: 997px) {
    height: 312px;
    margin-bottom: 80px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;

  @media screen and (min-width: 998px) {
    align-items: center;
    margin-left: ${px2rem(80)};
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
  }
`;

export const StyledTitle = styled(Title)`
  max-width: ${px2rem(290)};

  @media screen and (min-width: 998px) {
    flex-shrink: 0;
  }

  @media screen and (max-width: 997px) {
    font-size: 32px;
    margin-bottom: 16px;
  }
`;

export const StyledText = styled(Text)`
  position: relative;

  &::after {
    content: url(${DiagonalCornersBar});
    display: block;
    line-height: 0;
    margin-top: ${px2rem(16)};
    width: ${px2rem(169)};
  }

  @media screen and (min-width: 998px) {
    margin-left: ${px2rem(60)};
    max-width: ${px2rem(435)};
  }

  @media screen and (max-width: 997px) {
    max-width: 305px;
  }
`;
