import styled from 'styled-components';
import { StyledImage, Tooltip } from 'components/Solucoes/Tabs/styles';
import { px2rem } from 'src/util';

export const FlexibilidadeImage = styled(StyledImage)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(326)};
    width: ${px2rem(654)};
  }
`;

export const FlexibilidadeTooltip = styled(Tooltip)`
  left: ${px2rem(206)};
  top: -${px2rem(2)};
  height: ${px2rem(139)};
`;

export const AssinaturaImage = styled(StyledImage)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(327)};
    width: ${px2rem(728)};
  }
`;

export const AssinaturaTooltip = styled(Tooltip)`
  right: ${px2rem(297)};
  top: ${px2rem(50)};
  width: ${px2rem(270)};
`;

export const BaseImage = styled(StyledImage)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(327)};
    width: ${px2rem(655)};
  }
`;

export const BaseTooltip = styled(Tooltip)`
  right: ${px2rem(52)};
  top: ${px2rem(35)};
  height: ${px2rem(139)};
`;
