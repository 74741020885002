import React from 'react';
import { FAQ } from 'components/Solucoes';
import styled from 'styled-components';

const Link = styled.a`
  display: inline-block;
`;

const data = [
  {
    question: `Para usar o Nexodata Receitas eu preciso trocar minha interface pela de vocês?`,
    answer: (
      <p>
        Não, você pode escolher por manter sua interface e se conectar conosco
        através da nossa API ou, se preferir, temos uma interface pronta para
        você usar e customizamos ela à sua identidade visual. Ambas possuem as
        mesmas funcionalidades e a integração será feita da forma que te atender
        melhor.
      </p>
    )
  },
  {
    question: `Sou médico(a) e quero usar a Nexodata, como faço?`,
    answer: (
      <p>
        A Nexodata está integrada com centenas de parceiros de tecnologia e
        possivelmente o prontuário eletrônico que você usa também está e você
        pode habilitar a funcionalidade. De uma forma simples, nós nos
        relacionamos com os sistemas de tecnologia e não diretamente com os
        médicos. Para saber se o prontuário eletrônico que você usa é um
        parceiro Nexodata, basta entrar em contato diretamente com o suporte do
        seu sistema.
      </p>
    )
  },
  {
    question: `Como assinar uma receita de forma digital pela Nexodata?`,
    answer: (
      <p>
        Para que seus médicos assinem suas receitas de forma digital, é
        necessário utilizar o certificado nuvem SafeID (Safeweb), Bird ID
        (Soluti), Vidaas (Valid) ou qualquer outro certificado físico nos
        padrões do ICP-Brasil (A1 ou A3). Assinar uma receita de forma digital é
        extremamente importante porque dessa forma, o paciente poderá acessar a
        receita e comprar o medicamento sem a necessidade de ir até a farmácia
        com um papel impresso. Nosso time está preparado para esclarecer
        qualquer dúvida técnica que tenha, entre em contato através do{' '}
        <Link href="mailto:parceiros@nexodata.com.br" title="Entrar em contato">
          parceiros@nexodata.com.br.
        </Link>
      </p>
    )
  }
];

export default function ReceitasFAQ() {
  return <FAQ data={data} />;
}
