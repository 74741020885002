import React from 'react';
import { Title, Text } from 'components';
import { Wrapper, BackgroundImage, Grid, Content, IconsList } from './styles';

export default function Assinatura() {
  return (
    <Wrapper>
      <BackgroundImage
        alt=""
        className="desktop"
        src={require('assets/assinatura-bg.jpg')}
      />
      <Grid className="grid">
        <Content>
          <Title>Assinatura Digital</Title>
          <Text>
            Através da integração com o Nexodata Receitas, seus clientes passam
            a poder assinar quaisquer prescrições com qualquer certificado A1 ou
            A3 e vários certificados em nuvem dos nossos parceiros.
          </Text>
          <IconsList
            alt=""
            className="desktop"
            src={require('assets/assinatura-logos.jpg')}
          />
          <IconsList
            alt=""
            className="mobile"
            src={require('assets/assinatura-logos-mobile.jpg')}
          />
        </Content>
      </Grid>
      <BackgroundImage
        alt=""
        className="mobile"
        src={require('assets/assinatura-banner-mobile.jpg')}
      />
    </Wrapper>
  );
}
