import React from 'react';
import { Confie } from 'components/Solucoes';

const data = [
  {
    logo: require('assets/dr-tis-logo.jpg'),
    text: `Estamos muito satisfeitos com a parceria, pela prontidão no atendimento e por todos nossos clientes utilizarem a Nexodata com muita satisfação.`,
    customerName: 'Jihan',
    customerPosition: 'CEO - Dr. Tis'
  },
  {
    logo: require('assets/vitta-logo.jpg'),
    text: `Confiamos as nossas prescrições eletrônicas à Nexodata por estar em milhares de farmácias em todo o país, e por continuar capilarizando e crescendo essa rede com velocidade. A solução é completa e de boa usabilidade tanto para o profissional de saúde quanto para o paciente, e todo o time muito acessível aos parceiros a nível técnico e de serviço.`,
    customerName: 'Rodrigo Metedieri',
    customerPosition: 'Sócio - Vitta'
  },
  {
    logo: require('assets/tdsa-logo.jpg'),
    text: `Poder contar com um parceiro que entende a velocidade do setor de saúde e se adapta rapidamente a ela, trabalhando para facilitar a integração dos recursos faz toda a diferença.`,
    customerName: 'Jovane',
    customerPosition: 'CEO - TDSA Sistemas'
  }
];

export default function ReceitasConfie() {
  return <Confie data={data} />;
}
