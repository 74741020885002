import React, { useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import classNames from 'classnames';
import { CustomVisibilitySensor, Subtitle, Title, Text } from 'components';
import {
  Wrapper,
  BackgroundImage,
  Grid,
  Content,
  StyledSingleInputForm
} from './styles';

export default function Prescreva() {
  return (
    <CustomVisibilitySensor offset={{ bottom: -300, top: -300 }}>
      {({ isVisible }) => (
        <Wrapper>
          <BackgroundImage
            alt=""
            className={classNames({ isVisible })}
            src={require('assets/futuro-bg.jpg')}
          />
          <Grid className="grid">
            <Content>
              <Subtitle>Sempre com você</Subtitle>
              <Title>Prescreva de qualquer lugar</Title>
              <Text>
                O Nexodata Receitas também funciona no celular e o paciente
                recebe a prescrição na hora, sem precisar andar com o papel.
              </Text>
              <StyledSingleInputForm
                buttonText="Fale com um especialista"
                type="receitas"
              />
            </Content>
          </Grid>
        </Wrapper>
      )}
    </CustomVisibilitySensor>
  );
}
