import React from 'react';
import { CustomVisibilitySensor } from 'components';
import classNames from 'classnames';
import {
  Wrapper,
  Grid,
  Content,
  StyledImage,
  TextWrapper,
  StyledTitle,
  StyledText
} from './styles';

export default function Estamos() {
  return (
    <Wrapper>
      <Grid className="grid">
        <Content>
          <CustomVisibilitySensor>
            {({ isVisible }) => (
              <StyledImage
                alt=""
                className={classNames({ isVisible })}
                src={require('assets/estamos-bg.svg')}
              />
            )}
          </CustomVisibilitySensor>
          <TextWrapper>
            <StyledTitle>Estamos sempre aqui, por você!</StyledTitle>
            <StyledText>
              Nós genuinamente acreditamos na nossa relação de parceria e
              geração de valor. Conte com nosso time para o que precisar.
            </StyledText>
          </TextWrapper>
        </Content>
      </Grid>
    </Wrapper>
  );
}
