import styled from 'styled-components';
import { LoadImage, Title, Text } from 'components';
import { px2rem } from 'src/util';

const height = 1085;

export const Wrapper = styled.section`
  position: relative;

  @media screen and (min-width: 998px) {
    height: 0;
    padding-bottom: ${(height / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    padding-bottom: ${(501 / 375) * 100}%;
  }
`;

export const BackgroundImage = styled(LoadImage)`
  position: absolute;
  right: 0;
  bottom: 0;

  @media screen and (min-width: 998px) {
    height: 100%;
  }

  @media screen and (max-width: 997px) {
    width: 100%;
  }
`;

export const Grid = styled.div`
  @media screen and (min-width: 998px) {
    align-items: center;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
  }

  @media screen and (max-width: 997px) {
    padding: 120px 20px 86px;
  }
`;

export const Content = styled.div`
  ${Title} {
    margin-bottom: ${px2rem(24)};
  }

  @media screen and (min-width: 1441px) {
    grid-column: 2 / span 7;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 1 / span 8;
  }

  @media screen and (min-width: 998px) {
    ${Text} {
      max-width: ${px2rem(650)};
    }
  }

  @media screen and (max-width: 997px) {
  }
`;

export const IconsList = styled.img`
  @media screen and (min-width: 998px) {
    margin-top: ${px2rem(62)};
    width: ${px2rem(577)};
  }

  @media screen and (max-width: 997px) {
    margin: 45px auto 0;
    width: 271px;
  }
`;
