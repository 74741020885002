import React from 'react';
import { Contact, SEO } from 'components';
import { HowItWorks } from 'components/Solucoes';
import {
  ReceitasTopBanner,
  ReceitasTabs,
  Prescreva,
  ReceitasConfie,
  Assinatura,
  Integration,
  Estamos,
  ReceitasFAQ
} from 'components/Solucoes/Receitas';

export default function Receitas() {
  return (
    <>
      <SEO title="Nexodata" />
      <ReceitasTopBanner />
      <ReceitasTabs />
      <Prescreva />
      <HowItWorks />
      <ReceitasConfie />
      <Assinatura />
      <Integration />
      <Estamos />
      <ReceitasFAQ />
      <Contact type="receitas" />
    </>
  );
}
